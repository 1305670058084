:root {
    /* Colors */
    --color-primary: #2ecc71;     /* Lebendiges Grün */
    --color-primary-light: #55d98d;
    --color-primary-dark: #27ae60;
    --color-secondary: #ecf0f1;   /* Helles Grau */
    --color-text: #2c3e50;        /* Dunkelgrau */
    --color-text-light: #7f8c8d;
    --color-background: #f8f9fa;
    --color-accent: #3498db;      /* Blau für Akzente */
    --color-success: #27ae60;
    --color-warning: #f39c12;
    --color-error: #e74c3c;
  
    /* Typography */
    --font-size-base: 16px;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.25rem;
    --font-size-xl: 1.5rem;
    --font-size-xxl: 2rem;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --font-weight-big: 900;
  
    /* Spacing */
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 1.5rem;
    --spacing-xl: 2rem;
    --spacing-xxl: 3rem;
  
    /* Borders */
    --border-radius-sm: 0.25rem;  /* 4px / 16px = 0.25rem */
    --border-radius-md: 0.5rem;   /* 8px / 16px = 0.5rem */
    --border-radius-lg: 0.75rem;  /* 12px / 16px = 0.75rem */
  
    /* Shadows */
    --shadow-sm: 0 1px 10px rgba(0,0,0,0.08), 0 1px 12px rgba(0,0,0,0.12);
    --shadow-md: 0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08);
    --shadow-lg: 0 10px 20px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.05);
  
    /* Transitions */
    --transition-fast: 150ms ease-in-out;
    --transition-normal: 300ms ease-in-out;
    --transition-slow: 500ms ease-in-out;
  }