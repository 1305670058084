.contact-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  padding-top: 10vh;
}

.contact-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.contact-content {
  width: 100%;
  max-width: 600px;
  background-color: rgba(248, 249, 250, 0.85);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
}

.contact-info {
  width: 100%;
}

.contact-title {
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  font-weight: var(--font-weight-bold);
}

.company-info {
  margin-bottom: var(--spacing-md);
}

.company-info h2 {
  font-size: var(--font-size-lg);
  color: var(--color-text);
  margin-bottom: var(--spacing-xs);
}

.company-info p {
  color: var(--color-text-light);
  font-size: var(--font-size-sm);
}

.contact-details {
  display: grid;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.contact-item {
  display: flex;
  align-items: flex-start;
}

.contact-item .icon {
  font-size: var(--font-size-lg);
  color: var(--color-primary);
  margin-right: var(--spacing-sm);
  flex-shrink: 0;
}

.contact-item a,
.contact-item p {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  text-decoration: none;
  transition: var(--transition-fast);
}

.contact-item a:hover {
  color: var(--color-primary);
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
  color: white;
  background-color: #25D366;
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: var(--transition-fast);
}

.whatsapp-button:hover {
  background-color: #128C7E;
}

.whatsapp-button svg {
  margin-right: var(--spacing-xs);
}

@media (min-width: 768px) {
  .contact-container {
    justify-content: flex-end;
    height: 90vh;
  }

  .contact-content {
    width: 50%;
    height: 100%;
    max-width: none;
    border-radius: 0;
    box-shadow: none;
  }

  .contact-info {
    max-width: 500px;
    padding-left: var(--spacing-xl) ;
  }

  .contact-title {
    font-size: var(--font-size-xxl);
  }

  .company-info h2 {
    font-size: var(--font-size-xl);
  }

  .company-info p {
    font-size: var(--font-size-md);
  }

  .contact-item a,
  .contact-item p {
    font-size: var(--font-size-md);
  }

  .whatsapp-button {
    width: auto;
    font-size: var(--font-size-md);
  }
}

@media (max-width: 480px) {
  .contact-content {
    margin: var(--spacing-md);
    padding: var(--spacing-md);
  }

  .contact-title {
    font-size: var(--font-size-lg);
  }

  .company-info h2 {
    font-size: var(--font-size-md);
  }

  .company-info p {
    font-size: var(--font-size-xs);
  }

  .contact-item .icon {
    font-size: var(--font-size-md);
  }

  .contact-item a,
  .contact-item p {
    font-size: var(--font-size-xs);
  }

  .whatsapp-button {
    font-size: var(--font-size-xs);
  }
}