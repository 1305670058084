.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh;
  z-index: 1000;
  background: var(--color-background);
  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(10px) saturate(180%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.header--scrolled {
  background: rgba(248, 249, 250, 0.85);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
  padding: 0 clamp(1rem, 5vw, 4rem);
}

.header__logo-container {
  display: flex;
  align-items: center;
}

.header__logo {
  height: 60px;
  width: auto;
  transition: all 0.3s ease;
}

.header__logo:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 10px rgba(var(--color-primary), 0.5));
}

.header__nav {
  display: flex;
  align-items: center;
}

.header__nav-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: clamp(1rem, 3vw, 3rem);
}

.header__nav-item {
  position: relative;
}

.header__nav-link {
  text-decoration: none;
  color: var(--color-text);
  font-weight: 600;
  font-size: clamp(0.875rem, 1vw, 1rem);
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.header__nav-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(var(--color-primary), 0.1) 0%,
    rgba(var(--color-primary), 0.2) 100%
  );
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
  z-index: -1;
}

.header__nav-link:hover {
  color: var(--color-primary);
}

.header__nav-link:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.header__nav-link.active {
  color: var(--color-primary);
  background: linear-gradient(
    45deg,
    rgba(var(--color-primary), 0.1) 0%,
    rgba(var(--color-primary), 0.2) 100%
  );
  box-shadow: 0 4px 15px rgba(var(--color-primary), 0.2);
}

.header__nav-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: var(--color-primary);
  border-radius: 50%;
  transform: translateX(-50%) translateY(10px);
  opacity: 0;
  transition: all 0.3s ease;
}

.header__nav-link.active:hover::after {
  transform: translateX(-50%) translateY(5px);
  opacity: 1;
}

.header__menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  transition: color 0.3s ease;
}

.header__menu-toggle:hover {
  color: var(--color-primary);
}

@media (max-width: 992px) {
  .header {
    height: auto;
    padding: 1rem 0;
  }

  .header--scrolled {
    padding: 0.5rem 0;
  }

  .header__container {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .header__logo {
    height: 30px;
  }

  .header__menu-toggle {
    display: block;
  }

  .header__nav {
    flex-basis: 100%;
    display: none;
    background-color: var(--color-background);
    padding: 1rem;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
  }

  .header__nav--open {
    display: block;
  }

  .header__nav-list {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .header__nav-link {
    display: block;
    padding: 0.75rem 1.5rem;
  }
}

@media (max-width: 576px) {
  .header__nav-link {
    font-size: 0.875rem;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header__nav-item {
  animation: fadeInDown 0.6s ease-out forwards;
  opacity: 0;
}

.header__nav-item:nth-child(1) { animation-delay: 0.1s; }
.header__nav-item:nth-child(2) { animation-delay: 0.2s; }
.header__nav-item:nth-child(3) { animation-delay: 0.3s; }

.header__nav-link {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.header__nav-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(var(--color-primary), 0.2);
}

.header__nav-link:active {
  transform: translateY(1px);
}