.home-third-container {
  background-color: var(--color-secondary);
  padding: var(--spacing-xl) 0;
}

.home-third-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.home-third-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto var(--spacing-xl);
}

.home-third-intro p {
  font-size: var(--font-size-md);
  color: var(--color-text);
  line-height: 1.6;
}

.home-third-applications {
  background-color: var(--color-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
  box-shadow: var(--shadow-md);
}

.home-third-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.home-third-tab {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-text-light);
  transition: var(--transition-normal);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  flex: 1 1 calc(33.333% - var(--spacing-md));
  min-width: 120px;
  max-width: 250px;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  box-shadow: var(--shadow-sm);
}

.home-third-tab:hover,
.home-third-tab.active {
  background-color: var(--color-primary);
  color: white;
}

.home-third-tab-icon {
  background-color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-sm);
  transition: var(--transition-normal);
  border: 3px solid var(--color-primary);
}

.home-third-tab:hover .home-third-tab-icon,
.home-third-tab.active .home-third-tab-icon {
  background-color: var(--color-primary);
  border: 3px solid var(--color-background);
}

.home-third-tab:hover .home-third-tab-icon svg,
.home-third-tab.active .home-third-tab-icon svg {
  color: white;
}

.home-third-tab span {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
  width: 100%;
}

.home-third-tab-content {
  padding: var(--spacing-md);
  transition: var(--transition-normal);
}

.home-third-tab-content h3 {
  font-size: var(--font-size-lg);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
}

.home-third-tab-content ul {
  list-style-type: none;
  padding: 0;
}

.home-third-tab-content li {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.home-third-tab-content li svg {
  color: var(--color-primary);
  margin-right: var(--spacing-sm);
  flex-shrink: 0;
}

.home-third-recycling {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: var(--spacing-xl);
}

.home-third-recycling-content {
  margin-bottom: var(--spacing-lg);
}

.home-third-recycling h3 {
  font-size: var(--font-size-lg);
  color: var(--color-primary-dark);
  margin-bottom: var(--spacing-md);
}

.home-third-recycling p {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  line-height: 1.6;
}

.home-third-recycling-icon svg {
  color: var(--color-primary);
  width: 60px;
  height: 60px;
}

.home-third-cta {
  text-align: center;
  background: linear-gradient(135deg, var(--color-primary-light), var(--color-primary));
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  color: var(--color-background);
}

.home-third-cta h2 {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-sm);
}

.home-third-cta p {
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-md);
}

.home-third-slogan {
  background-color: var(--color-background);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md) var(--spacing-lg);
  margin: var(--spacing-lg) 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.home-third-slogan:hover {
  transform: scale(1.05);
}

.home-third-slogan p {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  margin: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
}

.home-third-button {
  background-color: var(--color-background);
  color: var(--color-primary-dark);
  border: none;
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm) var(--spacing-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  transition: var(--transition-normal);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.home-third-button svg {
  margin-right: var(--spacing-sm);
}

.home-third-button:hover {
  background-color: var(--color-secondary);
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

@media (min-width: 768px) {
  .home-third-content {
    padding: 0 var(--spacing-lg);
  }

  .home-third-intro p {
    font-size: var(--font-size-lg);
  }

  .home-third-applications {
    padding: var(--spacing-xl);
  }

  .home-third-tab {
    padding: var(--spacing-md);
  }

  .home-third-tab-icon {
    width: 80px;
    height: 80px;
  }

  .home-third-tab span {
    font-size: var(--font-size-md);
  }

  .home-third-tab-content {
    padding: var(--spacing-md) var(--spacing-lg);
  }

  .home-third-tab-content h3 {
    font-size: var(--font-size-xl);
  }

  .home-third-tab-content li {
    font-size: var(--font-size-md);
  }

  .home-third-recycling {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
  }

  .home-third-recycling-content {
    margin-right: var(--spacing-xl);
    margin-bottom: 0;
  }

  .home-third-recycling h3 {
    font-size: var(--font-size-xl);
  }

  .home-third-recycling p {
    font-size: var(--font-size-md);
  }

  .home-third-recycling-icon svg {
    width: 80px;
    height: 80px;
  }

  .home-third-cta {
    padding: var(--spacing-xl);
  }

  .home-third-cta h2 {
    font-size: var(--font-size-xxl);
  }

  .home-third-cta p {
    font-size: var(--font-size-lg);
  }

  .home-third-slogan {
    padding: var(--spacing-lg) var(--spacing-xl);
    margin: var(--spacing-xl) 0;
  }

  .home-third-slogan p {
    font-size: var(--font-size-xxl);
  }

  .home-third-button {
    padding: var(--spacing-md) var(--spacing-xl);
    font-size: var(--font-size-md);
  }
}