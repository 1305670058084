.title {
  font-size: calc(var(--font-size-xxl) * 1.5);
  color: var(--color-text);
  text-align: center;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;

}

.title-animated {
  display: inline-block;
  background: linear-gradient(45deg, var(--color-primary), var(--color-accent));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient-animation 5s ease infinite;
}

@keyframes gradient-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.title-static {
  display: block;
}

@media (max-width: 1024px) {
  .title {
    font-size: calc(var(--font-size-xxl) * 1.5);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-xxl);
  }
}

@media (max-width: 480px) {
  .title {
    font-size: calc(var(--font-size-xl) * 1.2);
  }

}

@media (max-width: 320px) {
  .title {
    font-size: var(--font-size-xl);
  }
}