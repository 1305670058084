.home-hero {
  position: relative;
  height: 90vh;
  margin-top: 10vh;
  width: 100%;
  overflow: hidden;
  background-color: var(--color-background);
  display: flex;
  align-items: center;
}

.home-hero__image-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  height: 100%;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  z-index: 1;
  overflow: hidden;
}

.home-hero__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right center;
  animation: pulse 30s ease-in-out infinite;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1) translateX(0);
  }
  50% {
    transform: scale(1.05) translateX(-2.38095%);
  }
}

.home-hero__content {
  position: relative;
  z-index: 2;
  width: 30%;
  padding-left: 10%;
}

.home-hero__title {
  font-size: 4.5rem;
  font-weight: 800;
  color: var(--color-text);
  line-height: 1.1;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards 0.2s;
}

.home-hero__title-main {
  display: block;
  background: var(--color-primary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradientShift 5s ease infinite;
}

.home-hero__title-sub {
  display: block;
  font-size: 2.5rem;
  font-weight: 300;
  color: var(--color-text);
}

.home-hero__description {
  font-size: 1.25rem;
  color: var(--color-text);
  margin-bottom: var(--spacing-xxl);
  max-width: 600px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards 0.4s;
}

.home-hero__tagline {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: var(--spacing-xxl);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards 0.6s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .home-hero {
    margin-top: 5vh;
  }

  .home-hero__content {
    width: 50%;
    padding-left: 5%;
  }

  .home-hero__image-container {
    width: 50%;
  }

  .home-hero__title {
    font-size: 3.5rem;
  }

  .home-hero__title-sub {
    font-size: 2rem;
  }

  .home-hero__description {
    font-size: 1.1rem;
  }

  .home-hero__tagline {
    font-size: 1.3rem;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .home-hero {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }

  .home-hero__content {
    width: 100%;
    padding: 2rem 1rem;
    order: 2;
    text-align: center;
  }

  .home-hero__image-container {
    position: relative;
    width: 100%;
    height: 50vh;
    clip-path: none;
    order: 1;
  }

  .home-hero__title {
    font-size: 3rem;
  }

  .home-hero__title-sub {
    font-size: 1.75rem;
  }

  .home-hero__description {
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .home-hero__tagline {
    font-size: 1.2rem;
  }

}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .home-hero__title {
    font-size: 2.5rem;
  }

  .home-hero__title-sub {
    font-size: 1.5rem;
  }

  .home-hero__description {
    font-size: 0.9rem;
  }

  .home-hero__tagline {
    font-size: 1.1rem;
  }

}