.business-model-first {
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: var(--font-size-md);
  padding: var(--spacing-xl) 0;
  margin-top: 10vh;
}

.business-model-first-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-xl);
}

.business-model-first-title {
  text-align: center;
  margin-bottom: var(--spacing-xxl);
}

.business-model-first-process {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.business-model-first-main-applications {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--spacing-xl);
}

.business-model-first-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 var(--spacing-md);
  padding: var(--spacing-xl);
  background-color: var(--color-secondary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
}

.business-model-first-subtitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
}

.business-model-first-text {
  font-size: var(--font-size-lg);
  line-height: 1.6;
  margin-bottom: var(--spacing-lg);
}

.business-model-first-image-container {
  display: grid;
  gap: var(--spacing-md);
}

.business-model-first-image-container-3 {
  grid-template-columns: repeat(3, 1fr);
}

.business-model-first-image-container-4 {
  grid-template-columns: repeat(2, 1fr);
}

.business-model-first-image-container-1 {
  grid-template-columns: repeat(2, 1fr);
}

.business-model-first-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  cursor: pointer;
}

.business-model-first-image-container-16-9 {
  grid-template-columns: repeat(2, 1fr);
}

.business-model-first-image-container-16-9 .business-model-first-image {
  aspect-ratio: 16 / 9;
}

.business-model-first-image:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.business-model-first-arrow {
  width: 48px;
  height: 48px;
  margin: var(--spacing-xl) 0;
  color: var(--color-primary);
}

.business-model-first-lifecycle {
  width: 100%;
}

/* Modal styles */
.business-model-first-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.business-model-first-modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

/* Media query for responsive design */
@media (max-width: 1024px) {
  .business-model-first{
    margin-top: 5vh;
  }
  
  .business-model-first-main-applications {
    flex-direction: column;
  }

  .business-model-first-section {
    margin: 0 0 var(--spacing-xl) 0;
  }
}

@media (max-width: 768px) {
  .business-model-first-image-container-3,
  .business-model-first-image-container-4,
  .business-model-first-image-container-16-9,
  .business-model-first-image-container-1 {
    grid-template-columns: 1fr;
  }

  .business-model-first-image {
    aspect-ratio: 16 / 9;
  }
}