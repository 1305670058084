.business-model-second {
  padding: var(--spacing-xl);
  background-color: var(--color-background);
  color: var(--color-text);
}

.business-model-second-content {
  max-width: 1200px;
  margin: 0 auto;
}

.business-model-second-title {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  text-align: center;
}

.business-model-second-slogan {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  text-align: center;
}

.business-model-second-description {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  background-color: var(--color-secondary);
  padding: var(--spacing-md) var(--spacing-lg);
  margin: var(--spacing-lg) auto;
  text-align: center;
  max-width: 800px;
  border-radius: var(--border-radius-md);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.business-model-second-description:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.business-model-second-timeline {
  position: relative;
  padding: var(--spacing-xl) 0;
}

.business-model-second-timeline::before {
  content: '';
  position: absolute;
  top: 50px;
  left: 50%;
  height: calc(100% - 100px);
  width: 2px;
  background-color: var(--color-primary);
}

.business-model-second-step {
  margin-bottom: var(--spacing-xl);
  position: relative;
}

.business-model-second-step:last-child {
  margin-bottom: 0;
}

.business-model-second-step-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-secondary);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  transition: var(--transition-normal);
}

.business-model-second-step-content:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.business-model-second-step:nth-child(even) .business-model-second-step-content {
  flex-direction: row-reverse;
}

.business-model-second-step-image {
  flex: 0 0 40%;
  max-width: 40%;
}

.business-model-second-step-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  cursor: pointer;
}

.business-model-second-step-image img:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.business-model-second-step-text {
  flex: 0 0 55%;
  max-width: 55%;
}

.business-model-second-step-text.full-width {
  flex: 0 0 100%;
  max-width: 100%;
}

.business-model-second-step-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  margin-bottom: var(--spacing-sm);
  display: flex;
  align-items: center;
}

.business-model-second-step-number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border-radius: 50%;
  margin-right: var(--spacing-sm);
  font-size: var(--font-size-sm);
}

.business-model-second-step p {
  font-size: var(--font-size-md);
}

/* Modal styles */
.business-model-second-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.business-model-second-modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .business-model-second {
    padding: var(--spacing-md);
  }

  .business-model-second-title {
    font-size: clamp(1.5rem, 5vw, var(--font-size-xxl));
  }

  .business-model-second-slogan {
    font-size: clamp(1.25rem, 4vw, var(--font-size-xl));
  }

  .business-model-second-description {
    font-size: clamp(1rem, 3vw, var(--font-size-lg));
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .business-model-second-step-content {
    flex-direction: column;
    padding: var(--spacing-md);
  }

  .business-model-second-step:nth-child(even) .business-model-second-step-content {
    flex-direction: column;
  }

  .business-model-second-step-image,
  .business-model-second-step-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .business-model-second-step-image {
    margin-bottom: var(--spacing-md);
  }

  .business-model-second-step-image img {
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .business-model-second-step-title {
    font-size: var(--font-size-md);
  }

  .business-model-second-step p {
    font-size: var(--font-size-sm);
  }
}

@media (max-width: 480px) {
  .business-model-second {
    padding: var(--spacing-sm);
  }

  .business-model-second-step-content {
    padding: var(--spacing-sm);
  }

  .business-model-second-step-title {
    font-size: var(--font-size-sm);
  }

  .business-model-second-step p {
    font-size: var(--font-size-xs);
  }
}