.footer {
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 3rem 2rem;
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, var(--color-primary), transparent);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  width: 120px;
  height: auto;
  margin-bottom: 1rem;
}

.footer-slogan {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-primary);
  margin: 0;
}

.footer-nav {
  display: flex;
  gap: 2rem;
}

.footer-link {
  color: var(--color-text);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
}

.footer-link::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.footer-link:hover {
  color: var(--color-primary);
}

.footer-link:hover::after {
  transform: scaleX(1);
}

.footer-bottom {
  max-width: 1200px;
  margin: 1rem auto 0;
  border-top: 1px solid rgba(var(--color-text-rgb), 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-copyright {
  font-size: 0.875rem;
  color: rgba(var(--color-text-rgb), 0.7);
}

.footer-credit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: rgba(var(--color-text-rgb), 0.7);
}

.footer-duobyte {
  display: flex;
  align-items: center;
}

.footer-duobyte-logo {
  width: 80px;
  height: auto;
  transition: transform 0.3s ease;
}

.footer-duobyte:hover .footer-duobyte-logo {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
  }

  .footer-main {
    align-items: center;
  }

  .footer-nav {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .footer-credit {
    flex-direction: column;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .footer-link::after {
    transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}