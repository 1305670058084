/* Impressum.css */
.impressum {
  padding: var(--spacing-xl) 0;
  background-color: var(--color-background);
  color: var(--color-text);
  margin-top: 10vh;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.impressum__title {
  font-size: var(--font-size-xxl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-lg);
  font-weight: var(--font-weight-bold);
}

.impressum__section {
  margin-bottom: var(--spacing-xl);
}

.impressum__section h2 {
  font-size: var(--font-size-lg);
  color: var(--color-primary-dark);
  margin-bottom: var(--spacing-md);
  font-weight: var(--font-weight-medium);
}

.impressum__section p {
  margin-bottom: var(--spacing-md);
  line-height: 1.6;
}

.impressum__section a {
  color: var(--color-accent);
  text-decoration: none;
  transition: color var(--transition-fast);
}

.impressum__section a:hover {
  color: var(--color-primary);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .impressum {
    padding: var(--spacing-lg) 0;
  }

  .impressum__title {
    font-size: var(--font-size-xl);
  }

  .impressum__section h2 {
    font-size: var(--font-size-md);
  }
}