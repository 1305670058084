.home-second-container {
    background-color: var(--color-background);
    overflow-x: hidden;
  }
  
  .home-second-content {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: var(--spacing-xl);
    padding: 0 var(--spacing-lg);
  }
  
  @keyframes gradient-animation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .home-second-title-static {
    display: block;
  }
  
  .home-second-benefits {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-lg);
    margin-bottom: var(--spacing-xl);
  }
  
  .home-second-benefit {
    flex: 1;
    background-color: var(--color-secondary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    transition: transform var(--transition-normal), box-shadow var(--transition-normal);
    position: relative;
    overflow: hidden;
  }
  
  .home-second-benefit:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-md);
  }
  
  .home-second-benefit::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(46, 204, 113, 0.1) 0%, transparent 70%);
    transform: scale(0);
    transition: transform var(--transition-slow);
  }
  
  .home-second-benefit:hover::before {
    transform: scale(1);
  }
  
  .home-second-benefit-icon {
    background-color: var(--color-primary-light);
    border-radius: 50%;
    padding: var(--spacing-sm);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .home-second-benefit-icon svg {
    color: var(--color-primary-dark);
  }
  
  .home-second-benefit p {
    font-size: var(--font-size-md);
    color: var(--color-text);
    margin: 0;
    position: relative;
    z-index: 1;
  }
  
  .home-second-certificate {
    background: linear-gradient(135deg, var(--color-primary), var(--color-primary-dark));
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    display: flex;
    align-items: center;
    gap: var(--spacing-lg);
    position: relative;
    overflow: hidden;
  }
  
  .home-second-certificate::before {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
    transition: transform var(--transition-slow);
  }
  
  .home-second-certificate:hover::before {
    transform: translate(50%, 50%);
  }
  
  .home-second-certificate-icon {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    padding: var(--spacing-md);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .home-second-certificate-icon svg {
    color: var(--color-background);
  }
  
  .home-second-certificate-content {
    color: var(--color-background);
  }
  
  .home-second-certificate h3 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-sm);
    font-weight: var(--font-weight-bold);
  }
  
  .home-second-certificate p {
    font-size: var(--font-size-md);
    opacity: 0.9;
    margin: 0;
  }
  
  /* Enhanced Features Section */
  .home-second-features {
    position: relative;
    padding-bottom: var(--spacing-xxl);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
  }
  
  .home-second-features::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    opacity: 0.1;
    animation: rotate 60s linear infinite;
  }
  
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .home-second-features-title {
    font-size: var(--font-size-xxl);
    color: var(--color-primary);
    margin-bottom: var(--spacing-xl);
    text-align: center;
    font-weight: var(--font-weight-bold);
    position: relative;
    z-index: 1;
  }
  
  .home-second-features-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background-color: var(--color-primary);
    transition: width var(--transition-normal);
  }
  
  .home-second-features-title:hover::after {
    width: 100px;
  }
  
  .home-second-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-xl);
    position: relative;
    z-index: 1;
  }
  
  .home-second-feature {
    background-color: var(--color-secondary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    transition: transform var(--transition-normal), box-shadow var(--transition-normal);
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  .home-second-feature::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-primary);
    opacity: 0;
    transition: opacity var(--transition-normal);
  }
  
  .home-second-feature:hover::before,
  .home-second-feature.active::before {
    opacity: 0.7;
  }
  
  .home-second-feature:hover,
  .home-second-feature.active {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
  
  .home-second-feature-icon {
    background-color: var(--color-background);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacing-md);
    transition: all var(--transition-normal);
  }
  
  .home-second-feature:hover .home-second-feature-icon,
  .home-second-feature.active .home-second-feature-icon {
    background-color: var(--color-primary);
    transform: rotate(360deg);
  }
  
  .home-second-feature-icon svg {
    color: var(--color-primary);
    transition: all var(--transition-normal);
    font-size: 40px;
  }
  
  .home-second-feature:hover .home-second-feature-icon svg,
  .home-second-feature.active .home-second-feature-icon svg {
    color: var(--color-background);
    transform: scale(1.1);
  }
  
  .home-second-feature h4 {
    font-size: var(--font-size-lg);
    color: var(--color-primary-dark);
    margin-bottom: var(--spacing-sm);
    font-weight: var(--font-weight-bold);
    position: relative;
    z-index: 1;
    transition: color var(--transition-normal);
  }
  
  .home-second-feature:hover h4,
  .home-second-feature.active h4 {
    color: var(--color-background);
  }
  
  .home-second-feature p {
    font-size: var(--font-size-md);
    color: var(--color-text-light);
    margin: 0;
    position: relative;
    z-index: 1;
    transition: color var(--transition-normal);
  }
  
  .home-second-feature:hover p,
  .home-second-feature.active p {
    color: var(--color-background);
  }
  
  @media (max-width: 768px) {
    .home-second-title {
      font-size: var(--font-size-xxl);
    }
    
    .home-second-benefits {
      flex-direction: column;
    }
    
    .home-second-certificate {
      flex-direction: column;
      text-align: center;
    }
    
    .home-second-features-grid {
      grid-template-columns: 1fr;
    }
  }