/* CSS for HomeFirst component */
.home-first-container {
  background-color: var(--color-secondary);
  padding: var(--spacing-xl);
}

.home-first-content {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: var(--spacing-xxl);
}

.home-first-subtitle {
  font-size: var(--font-size-lg);
  color: var(--color-text-light);
  text-align: center;
  margin-bottom: var(--spacing-xl);
}

.home-first-preHeader{
  font-size: var(--font-size-xl);
  color: var(--color-text-light);
  text-align: center;
  margin-bottom: calc(var(--spacing-lg) * -1);
}

.home-first-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-xxl);
}

.home-first-card {
  background-color: var(--color-background);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-lg);
  transition: var(--transition-normal);
}

.home-first-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.home-first-card h2 {
  font-size: var(--font-size-xl);
  color: var(--color-primary-dark);
  margin-bottom: var(--spacing-md);
}

.home-first-card ul {
  list-style-type: none;
  padding: 0;
}

.home-first-card li {
  font-size: var(--font-size-md);
  color: var(--color-text);
  margin-bottom: var(--spacing-sm);
  padding-left: var(--spacing-md);
  position: relative;
}

.home-first-card li::before {
  content: "•";
  color: var(--color-accent);
  position: absolute;
  left: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .home-first-container {
    padding: var(--spacing-md);
  }

  .home-first-content {
    margin-bottom: var(--spacing-lg);
  }

  .home-first-subtitle {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-md);
  }

  .home-first-grid {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }

  .home-first-card {
    padding: var(--spacing-md);
  }

  .home-first-card h2 {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-sm);
  }

  .home-first-card li {
    font-size: var(--font-size-sm);
    margin-bottom: var(--spacing-xs);
    padding-left: var(--spacing-sm);
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .home-first-container {
    padding: var(--spacing-sm);
  }

  .home-first-subtitle {
    font-size: var(--font-size-sm);
  }

  .home-first-card h2 {
    font-size: var(--font-size-md);
  }

  .home-first-card li {
    font-size: var(--font-size-xs);
  }
}

/* Ensure smooth transitions for hover effects on touch devices */
@media (hover: none) {
  .home-first-card:hover {
    transform: none;
    box-shadow: none;
  }
}

/* Improve readability of the Title component on smaller screens */
@media (max-width: 768px) {
  .title-container h1 {
    font-size: clamp(1.25rem, 4vw, 2rem);
  }
}